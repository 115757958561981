import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import CustomerRoutes from './views/customer/CustomerRoutes';
import ProductRoutes from './views/product/ProductRoutes';
import OrderRoutes from './views/order/OrderRoutes';
import CategoryRoutes from './views/category/CategoryRoutes';
import BannerRoutes from './views/banner/BannerRoutes';
import FAQRoutes from './views/faq/FAQRoutes';
import CouponRoutes from './views/coupon/CouponRoutes';
import { useSelector } from 'react-redux';
import FlightRoutes from './views/flight/FlightRoutes';
import PopularDestinationRoutes from './views/populardestination/PopularDestinationRoutes';
import MarkupRoutes from './views/markup/MarkupRoutes';
import TransRoutes from './views/trans/TransRoutes';
import ProductTable from './form/ProductTable';
import SectorRoutes from './views/sector/SectorRoutes';
import GalleryRoutes from './views/gallery/GalleryRoutes';
import OfficeRoutes from './views/office/OfficeRoutes';
import ProfileRoutes from './views/profile/ProfileRoutes';
import PackageRoute from './views/package/PackageRoutes';
import FooterRoutes from './views/footer/FooterRoutes';
import DefaultMarkup from './views/DefaultMarkup';
import PopularDestinationDomRoutes from './views/populardesdom/PopularDestinationDomRoutes';
import BusRoutes from './views/Bus/BusRoutes';
import SeoRoutes from './views/seo/SeoRoutes';
import HotelRoute from './views/hotel/HotelRoutes';
// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(
	lazy(() => import('app/views/sessions/JwtRegister'))
);
const ForgotPassword = Loadable(
	lazy(() => import('app/views/sessions/ForgotPassword'))
);
// echart page
const AppEchart = Loadable(
	lazy(() => import('app/views/charts/echarts/AppEchart'))
);
// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
const Inventory = Loadable(lazy(() => import('app/views/dashboard/Inventory')));
const PrivacyPolicySection = Loadable(
	lazy(() => import('app/views/privacy/PrivacyPolicySection'))
);
const TermandConditionSection = Loadable(
	lazy(() => import('app/views/termandcondition/TermandConditionSection'))
);
const AboutusPage = Loadable(
	lazy(() => import('app/views/aboutus/AboutusPage'))
);

const routes = [
	{
		element: (
			<AuthGuard>
				<MatxLayout />
			</AuthGuard>
		),
		children: [
			...materialRoutes,
			// dashboard route
			{
				path: '/dashboard/default',
				element: <Analytics />,
				auth: authRoles.admin,
			},
			{
				path: '/dashboard/agenttran',
				element: <ProductTable />,
				auth: authRoles.admin,
			},
			{
				path: '/dashboard/inventory',
				element: <Inventory />,
				auth: authRoles.admin,
			},
			{
				path: '/dashboard/privacy',
				element: <PrivacyPolicySection />,
				auth: authRoles.admin,
			},
			{
				path: '/dashboard/termcondition',
				element: <DefaultMarkup />,
				auth: authRoles.admin,
			},
			,
			// {
			// 	path: '/dashboard/aboutus',
			// 	element: <AboutusPage />,
			// 	auth: authRoles.admin,
			// },

			// e-chart rooute
			{
				path: '/charts/echarts',
				element: <AppEchart />,
				auth: authRoles.editor,
			},
			...CustomerRoutes,
			...ProductRoutes,
			...OrderRoutes,
			...CategoryRoutes,
			...BannerRoutes,
			...FAQRoutes,
			...CouponRoutes,
			...FlightRoutes,
			...PopularDestinationRoutes,
			...MarkupRoutes,
			...TransRoutes,
			...SectorRoutes,
			...GalleryRoutes,
			...OfficeRoutes,
			...ProfileRoutes,
			...PackageRoute,
			...FooterRoutes,
			...PopularDestinationDomRoutes,
			...BusRoutes,
			...SeoRoutes,
			...HotelRoute,
		],
	},

	// session pages route
	{ path: '/session/404', element: <NotFound /> },
	{ path: '/session/signin', element: <JwtLogin /> },
	{ path: '/session/signup', element: <JwtRegister /> },
	{ path: '/session/forgot-password', element: <ForgotPassword /> },

	{ path: '/', element: <Navigate to='/dashboard/bannerlist' /> },
	{ path: '*', element: <NotFound /> },
];

export default routes;
