import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddPackage = Loadable(lazy(() => import('./AddHotel')));
const PackageList = Loadable(lazy(() => import('./HotelList')));

const HotelRoute = [
	{
		path: '/dashboard/hotellist',
		element: <PackageList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addhotel',
		element: <AddPackage />,
		auth: authRoles.admin,
	},
];

export default HotelRoute;
